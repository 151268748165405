import { Icon, Box, Container, Text, Flex, Heading, SimpleGrid, HStack } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import { ContactUsBannerWithForm, Hero, SubHero, MainServiceBanner, CompareSlider } from '../../../components/shared';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useRemodelingService } from '../../../GraphQL/Queries';

const Remodeling = ({ location }) => {
  const data = useRemodelingService();

  const {
    acf_text_block_title,
    acf_image_text_block,
    acf_service_info,
    acf_services_block,
    acf_quote,
  } = data.residentialServiceBy;

  return (
    <Layout location={location}>
      <SEO title="Remodeling" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />
      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />
      <Container mt={{ base: '1rem', md: '10rem' }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} pl={{ base: '2rem', md: 'unset' }}>
          <Heading
            textStyle="servicePageHeading"
            color="primary"
            borderRight={{ base: '0', md: '2px' }}
            borderColor="primary"
            mr="2rem"
            w={{ base: 'unset', md: '17%' }}
          >
            {acf_image_text_block.blockList[1].title}
          </Heading>
          <Box w={{ base: 'unset', md: '60%' }} mt={{ base: '1rem', md: 'unset' }}>
            <Text textStyle="servicePageText" pr={{ base: '2rem', md: 'unset' }}>
              {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          alignItems="center"
          pl={{ base: '2rem', md: 'unset' }}
          pt={{ base: '2rem', md: '3.5rem' }}
        >
          <Box zIndex="2" w={{ base: 'unset', md: '45%' }} pr={{ base: '2rem', md: '3rem' }}>
            <Heading
              mt={{ base: '2rem', md: '-1rem' }}
              display={{ base: 'block', md: 'block' }}
              textStyle="servicePageHeading"
              color="primary"
            >
              {acf_services_block.servicesList[0].serviceTitle}
            </Heading>
            <Text textStyle="servicePageText" pt="0.5rem">
              {acf_services_block.servicesList[0].serviceDescription}
            </Text>
          </Box>

          <Box w={{ base: 'unset', md: '55%' }}>
            <CompareSlider
              image1={acf_services_block.servicesList[0].serviceGallery[0].sourceUrl}
              image2={acf_services_block.servicesList[0].serviceGallery[1].sourceUrl}
              height="100%"
            />
          </Box>
        </Flex>

        <Flex
          flexDirection={{ base: 'column-reverse', md: 'row-reverse' }}
          alignItems="center"
          pl={{ base: '2rem', md: 'unset' }}
          pt={{ base: '2rem', md: '3.5rem' }}
        >
          <Box
            zIndex="2"
            w={{ base: 'unset', md: '45%' }}
            pl={{ base: 'unset', md: '3rem' }}
            pr={{ base: '2rem', md: 'unset' }}
          >
            <Heading
              mt={{ base: '2rem', md: '-1rem' }}
              display={{ base: 'block', md: 'block' }}
              textStyle="servicePageHeading"
              color="primary"
            >
              {acf_services_block.servicesList[1].serviceTitle}
            </Heading>
            <Text textStyle="servicePageText" pt="1rem">
              {acf_services_block.servicesList[1].serviceDescription}
            </Text>
          </Box>
          <Box w={{ base: 'unset', md: '55%' }}>
            <CompareSlider
              image1={acf_services_block.servicesList[1].serviceGallery[0].sourceUrl}
              image2={acf_services_block.servicesList[1].serviceGallery[1].sourceUrl}
              height="100%"
            />
          </Box>
        </Flex>

        <Flex
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          pl={{ base: '2rem', md: 'unset' }}
          pt={{ base: '2rem', md: '3.5rem' }}
        >
          <Box zIndex="2" w={{ base: 'unset', md: '43%' }} pr={{ base: '2rem', md: 'unset' }}>
            <Heading
              mt={{ base: '2rem', md: '-1rem' }}
              display={{ base: 'block', md: 'block' }}
              textStyle="servicePageHeading"
              color="primary"
            >
              {acf_services_block.servicesList[2].serviceTitle}
            </Heading>
            <Text textStyle="servicePageText" py="1rem">
              {acf_services_block.servicesList[2].serviceDescription}
            </Text>
            <SimpleGrid w="100%" columns="2" pb="1rem" spacing={2}>
              <HStack alignItems="center">
                <Icon as={AiFillCheckCircle} w={4} h={4} color="primary" />
                <Text textStyle="servicePageText">Porcelain</Text>
              </HStack>
              <HStack alignItems="center">
                <Icon as={AiFillCheckCircle} w={4} h={4} color="primary" />
                <Text textStyle="servicePageText">Wood</Text>
              </HStack>
              <HStack alignItems="center">
                <Icon as={AiFillCheckCircle} w={4} h={4} color="primary" />
                <Text textStyle="servicePageText">Ceramic</Text>
              </HStack>
              <HStack alignItems="center">
                <Icon as={AiFillCheckCircle} w={4} h={4} color="primary" />
                <Text textStyle="servicePageText">Travertine</Text>
              </HStack>
              <HStack alignItems="center">
                <Icon as={AiFillCheckCircle} w={4} h={4} color="primary" />
                <Text textStyle="servicePageText">Vinyl planking</Text>
              </HStack>
            </SimpleGrid>
          </Box>

          <Box w={{ base: 'unset', md: '55%' }}>
            <CompareSlider
              image1={acf_services_block.servicesList[2].serviceGallery[0].sourceUrl}
              image2={acf_services_block.servicesList[2].serviceGallery[1].sourceUrl}
              height="100%"
            />
          </Box>
        </Flex>

        <Flex
          flexDirection={{ base: 'column-reverse', md: 'row-reverse' }}
          alignItems="center"
          pl={{ base: '2rem', md: 'unset' }}
          pt={{ base: '2rem', md: '3.5rem' }}
          mb={{ base: '5rem', md: '10rem' }}
        >
          <Box
            zIndex="2"
            w={{ base: 'unset', md: '45%' }}
            pl={{ base: 'unset', md: '3rem' }}
            pr={{ base: '2rem', md: 'unset' }}
          >
            <Heading
              mt={{ base: '2rem', md: '-1rem' }}
              display={{ base: 'block', md: 'block' }}
              textStyle="servicePageHeading"
              color="primary"
            >
              {acf_services_block.servicesList[3].serviceTitle}
            </Heading>
            <Text textStyle="servicePageText" pt="1rem">
              {acf_services_block.servicesList[3].serviceDescription}
            </Text>
          </Box>
          <Box w={{ base: 'unset', md: '55%' }}>
            <CompareSlider
              image1={acf_services_block.servicesList[3].serviceGallery[0].sourceUrl}
              image2={acf_services_block.servicesList[3].serviceGallery[1].sourceUrl}
              height="100%"
            />
          </Box>
        </Flex>
      </Container>

      {/* <ProjectsSection residential /> */}
      <Box mb={{ md: '15rem' }}></Box>

      <ContactUsBannerWithForm
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default Remodeling;
